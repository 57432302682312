.select {
    width: 100%;
}
.valueContainer {
    padding: 10px !important;
    p {
        position: static;
    }
}
.close {
    height: 50px;
    width: 50px;
    margin: auto;
}
.closeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.label {
    background-color: #fff;
    z-index: 0;
    padding: 0 5px;
}
