.container {
    border-bottom: 1px solid rgb(191, 191, 191);
    padding: 5px;
}
.button {
    margin-top: 5px;
    font-size: 12px;
    padding: 2px;
    // background-color: white;
    // border: 1px solid grey;
    // border-radius: 50%;
    min-width: 40px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon {
    font-size: 12px !important;
    min-width: 15px;
    height: 15px;
}
