.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 30px;
    .button {
        margin: 10px;
    }
}
.container {
    flex-grow: 1;
    padding: 50px;
}
.inputs {
    min-width: 60% !important;
    width: 100%;
    margin-bottom: 10px !important;
}
.inputContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
    // height: 40vh;
}
.datePicker {
    width: 100%;
}
.datePickerContainer {
    margin-top: 30px;
}
.customerLink {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
}
.viewTicketsButton {
    margin-top: 20px !important;
}
