.containerBox {
    display: flex;
    justify-content: center;
    background-color: #fff;
}
.container {
    margin: 15px;
    max-width: 450px;
    // width: 450px;
}
