.item {
    display: flex;
    border-bottom: 1px solid rgb(230, 230, 230);
    padding: 3px;
}
.button {
    margin-top: 5px;

    font-size: 12px;
    padding: 2px;
    // background-color: white;
    // border: 1px solid grey;
    // border-radius: 50%;
    min-width: 40px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
