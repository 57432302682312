.container {
    // border: 1px solid grey;
    border-radius: 8px;
    padding: 12px 15px;
    margin: 10px 0px 5px;
    white-space: break-spaces;
    width: 80%;
    font-size: 0.8em;
    font-weight: 400;
    background: rgb(255, 238, 176);
    color: #000;
    margin-left: 8px;
    box-sizing: border-box;
}
.myMessage {
    background: rgb(57, 155, 255);
    color: #fff;
    margin-left: auto;
    margin-right: 8px;
}
@mixin date {
    font-size: 0.6em;
    font-style: italic;
    margin: 0 20px 5px;
    color: grey;
}
.myDate {
    @include date;
    text-align: right;
}

.hisDate {
    @include date;
    text-align: left;
}

.textSpan {
    width: 50%;
}
