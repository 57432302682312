.label {
    font-size: 0.8em;
    color: grey;
    * {
        margin-left: 5px;
    }
}
.previewContainer {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    height: 232px;
    min-width: 20vw;
    margin: 20px;
    background-position: left bottom;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;
    .preview {
        height: 100%;
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: left center;
        display: flex;
        justify-content: flex-end;
        font-size: 20px;
        font-weight: 600;
        align-items: center;
        margin-right: 20px;
        .titleText {
            text-align: center;
            flex-basis: 50%;
            color: #0b5ff7;
            font-family: Raleway;
        }
    }
}
