.container {
    margin: 15px;
    max-width: 450px;
}

.userCard {
    padding: 10px 0;
    border-bottom: 1px solid rgb(226, 226, 226);
}
.offset {
    visibility: hidden;
    height: 1px;
}
.chatContainer {
    margin: 32px 0px 20px -65px;
    max-height: 70vh;
    min-height: 50vh;
    height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(218, 218, 218);
}

.avatar {
    width: 30px;
    height: 30px;
    // z-index: 10;
}
.userInfoContainer {
    width: 90%;
    margin-left: 15px;
    max-width: 65vw;
}
.userInfoContainerFull {
    width: 90%;
    margin-left: 15px;
    max-width: 65vw;
}
.expandButtonContainer {
    display: flex;
    justify-content: flex-end;
    height: 0px;
}
.textSendContainer {
    display: flex;
    justify-content: space-between;
}
.expandButton {
    position: relative;
    top: -30px;
}
.messagePreviewText {
    font-size: 0.8em;
    margin-top: 5px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 370px;
    white-space: pre;
}
.messagePreviewDate {
    margin-top: 5px;
    font-size: 0.6em;
    color: rgb(172, 172, 172);
    font-weight: 400;
}
.searchContainer {
    margin-bottom: 15px;
    min-width: 29vw;
}
.placeholder {
    color: rgb(204, 204, 204);
    font-size: 1.2em;
    text-align: center;
}

.label {
    font-size: 0.8em;
    color: grey;
    * {
        margin-left: 5px;
    }
}
.previewContainer {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    height: 232px;
    min-width: 20vw;
    margin: 20px;
    background-position: left bottom;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;
    .preview {
        height: 100%;
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: left center;
        display: flex;
        justify-content: flex-end;
        font-size: 20px;
        font-weight: 600;
        align-items: center;
        margin-right: 20px;
        .titleText {
            text-align: center;
            flex-basis: 50%;
            color: #0b5ff7;
            font-family: Raleway;
        }
    }
}

