.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 30px;
}
.container {
    flex-grow: 1;
    padding: 50px;
}
.inputs {
    min-width: 60% !important;
}
.inputContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
    // height: 40vh;
}
.datePicker {
    width: 100%;
}
.datePickerContainer {
    margin-top: 30px;
}
.panel {
    width: 100%;
}
