.table {
    margin-top: 30px;
    margin-bottom: 30px;
    border-collapse: collapse;
    td,
    th {
        border: 2px solid;
        padding: 4px;
        border-color: grey !important;
    }
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    // margin-top: 100px;
}
.container {
    flex-grow: 1;
}
.inputs {
    min-width: 60% !important;
    min-height: 50px;
}
.inputContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
