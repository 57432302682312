.container {
    display: flex;
    margin-left: 10px;
}
.popup {
    position: absolute;
    left: 20vw;
    background: white;
    padding: 10px;
    z-index: 10;
    // box-shadow: -12px -2px 253px 54px rgba(0, 0, 0, 0.75);
    box-shadow: -12px -2px 300px 70px rgba(204, 204, 204, 0.9);
    // Copy Text    // ::before {
    //     z-index: 2;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     background-color: rgba(210, 242, 232, 0.46);
    //     content: ' ';
    //     width: 100vw;
    //     display: block;
    //     height: 100vh;
    // }
}
.label {
    font-size: 0.8em;
    color: grey;
    * {
        margin-left: 5px;
    }
}
.input {
    max-width: 100px;
    font-size: 10px;
    height: 25px;
}
.button {
    font-size: 12px;
    padding: 2px;
    // background-color: white;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 50%;
    margin-left: 5px;
    min-width: 20px;
    min-height: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon {
    font-size: 12px !important;
    // min-width: 15px;
    // height: 15px;
}
