.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 30px;
    .button {
        margin: 10px;
    }
}
.container {
    flex-grow: 1;
    padding: 50px;
}
.inputContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
}
