.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 30px;
}
.container {
    flex-grow: 1;
}
.inputs {
    min-width: 60% !important;
}
.inputContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
    height: 40vh;
}
.buttonContainer {
    margin-top: 20px;
    padding: 20px;
}

.panel {
    width: 100%;
}

.titleBar {
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)
}

.titleBar .title {
    color: #4791db
}

